.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ck-editor__editable{
  height: 400px;
}

/* For WebKit-based browsers */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width as desired */
}

::-webkit-scrollbar-track {
  background-color: #f7f7f7; /* Change the background color to the desired lighter gray shade */
}

::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Change the thumb color to the desired lighter gray shade */
  border-radius: 4px; /* Add border-radius to create a rounded shape */
}

/* For Firefox */
/* You can adjust the width and colors for Firefox separately if desired */
/* Note: Firefox does not support customizing the track color */
* {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f7f7f7;
}

figure.image_resized img{
  width: 100%;
}
figure.image{
  display: flex;
  justify-content: center;
  margin:auto;
}
figure.image.image-style-side{
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.ellipsis{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.swiper-pagination-bullet{
  margin: 0 !important;
  border-radius:10px !important;
  background:#CB043F !important;
  width: 20px !important;
  height: 5px !important;
}
.toggle-bg{
  background-color:dodgerblue;
}